<template>
  <router-view></router-view>
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
   // HelloWorld
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&family=Changa:wght@400;700&family=Noto+Kufi+Arabic:wght@400;700&family=Tajawal:wght@400;700&display=swap');
:root {
  --main-text-color: #ffffff;
  --sub-text-color:#818181;
  --cairo : 'Cairo', sans-serif;
  --black:#1f1f1f;
  --light-black:#2c2c2c;
--changa: 'Changa', sans-serif;
--noto: 'Noto Kufi Arabic', sans-serif;
--tajwal: 'Tajawal', sans-serif;
  --card-gradiant:linear-gradient(28deg, rgba(246,246,254,1) 0%, rgba(221,226,248,1) 100%);
direction: rtl;
--link:#00c8ff;
}
.card{
  -webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
background:var(--light-black);
padding:10px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
width:90%;
margin:0 auto 20px auto;
}
body{
  background:var(--black);
  max-width:800px;
  margin:0 auto 0 auto;
  height:100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  
  color: var(--main-text-color);
  
}
html{
  border-top:10px solid #FF9E33;
  
   margin: 0px;
   height: 100%;
   width: 100%;

}
</style>

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDaAkWlg0DPOyVubH_geiYNA7w2oszPDX0",
  authDomain: "workshop-fef69.firebaseapp.com",
  databaseURL: "https://workshop-fef69-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "workshop-fef69",
  storageBucket: "workshop-fef69.appspot.com",
  messagingSenderId: "14669327689",
  appId: "1:14669327689:web:f64e27f1f0f144cb22be98"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
console.log(app);
<template>
  <div class="main-container">
    <h1>قسم الدراسات العملية</h1>
    <p class="element-text dark">مدرسة إياس بن معاذ</p>

    <img alt="Vue logo" src="../assets/esp.png" />
    <p class="text">تسجيل الحضور بورشة ESP8266-WiFi</p>
    <div class="container">
      <div v-for="(teacher, index) in teachersList" :key="index" class="teacher-element">
        <div class="time-container">
        <div class="time element">{{ teacher.time }}</div><div class="school element">{{ teacher.school }}</div>
        </div>
        <div class="name element">{{ teacher.name }}</div>
        
        
      </div>
    </div>
    <div class="footer">
      <div class="logo-container">
        <img class="logo" alt="Vue logo" src="../assets/logo.png" />
      </div>
      <div>
        <p class="info-text">مدرسة إياس بن معاذ</p>
        <p class="info-text">قسم الدراسات العملية / الكهرباء</p>
        <p class="info-text">رئيس القسم: الأستاذ يوسف الكندري</p>

        <p class="info-text">إشراف الموجه الفني: الدكتور أحمد مساعد</p>
        <p class="info-text">مدير المدرسة: الأستاذ علي الدريع</p>
      </div>

      
    </div>
  </div>
</template>

<script>
// import axios from "axios"
// import FormData from "form-data"
// import { handleError } from '@vue/runtime-core'
import "@/fireabase"

import { getDatabase, ref, onValue } from "firebase/database"
const db = getDatabase()

export default {
  name: "TeachersView",
  props: {
    msg: String,
  },
  computed: {
    teachersList() {
      var teachersArray = []

      if (this.teachers == null) return []
      Object.keys(this.teachers).forEach((key) => {
        teachersArray.push({ key: key, name: this.teachers[key].name, school: this.teachers[key].school, time: this.teachers[key].time })
      })
      console.log(teachersArray)
      if (teachersArray.length > 0) return teachersArray
      else return []
    },
  },
  data() {
    return {
      name: "",
      school: "",
      isTime: false,
      submitted: false,
      teachers: [],
    }
  },
  async mounted() {
    //const UID = await this.$store.dispatch("setNewUID");
    const post = ref(db, "teachers/")
    onValue(post, (x) => {
      this.teachers = x.val()
      console.log("TEACHERS", this.teachers)
    })

    //console.log("STORE:", await store.get("submitted"))
    //console.log("MATCH:", this.$route.params.id)

    //console.log("STORAGE PLAYER ID : ", playerId);

    //console.log("IS HE SUBMITTED", this.alreadySubmitted)
  },
  methods: {
    //   async handleSubmit() {
    //     var data = new FormData();
    //     data.append("entry.74666012",this.name);
    //     data.append("entry.141803270",this.school);
    //     var response = await axios({
    //       url: "https://docs.google.com/forms/d/1FAIpQLScnmR7M4JHKuKGefvKlk9D32FjLZJ6du_iImh4GBQBPufi5kA/formResponse",
    //       method: "post",
    //       body: data,
    //       headers: {
    //         Accept: "application/json",
    //       },
    //     })
    //     if (response.ok) {
    //      console.log(response)
    //     } else {
    //      console.log(response)
    //     }
    //   },
    // },
    // methods: {
    //   async register() {
    //     const response = await axios({
    //       method: "post",
    //       url: "https://formspree.io/f/xgeqdnqy",
    //       data: formData,
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     })
    //     console.log(response)
    //   },
    // },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
hr {
  border: none;
  border-top: 3px solid #ff9e33;
  width: 60%;
}
.footer {
  
}
.teacher-element{
  border:1px solid rgb(41, 41, 41);
  width:95%;
  margin:10px auto 0 auto;
  background:black;
  border-radius: 5px;
  font-family: "Cairo";
}
.element {
 margin:0 0 0 0;
}
.time{
  text-align: right;
  margin-right:10px;
  color:#ff9e33;
  display:inline-block;
}
.time-container{
  text-align: right;
}
.name{
  color:white;
  text-align: center;
  margin-bottom:10px;
}
.school{
  text-align: left;
  margin-right: 10px;
  color:rgb(139, 139, 139);
  display:inline-block;
}
.input-container {
  margin-top: 100px;
}
.button {
  display: block;
  width: 80%;
  border: none;
  border-radius: 5px;
  background: white;
  color: black;
  font-family: "Cairo";
  font-size: 20px;
  margin: 30px auto 100px auto;
  padding: 5px;
  text-align: center;
  outline: none;
}
.button:active {
  background: black;
  color: white;
}
.thank-you-container {
  margin-top: 150px;
}
.link {
}
.input {
  outline: none;
  color: white;
  display: block;
  width: 80%;
  border: none;
  border-radius: 5px;
  background: black;
  font-family: "Cairo";
  font-size: 20px;
  margin: 10px auto 10px auto;
  padding: 5px;
  text-align: center;
}
.flex-element-right {
  flex: 1;
}
.flex-element-left {
  flex: 3;
  text-align: right;
  font-family: var(--tajwal);
}
.element-text {
  font-size: 16px;
  margin: 0 0 0 0;
  font-family: var(--tajwal);
}
.image-title {
  font-size: 16px;
  margin: 0 0 0 0;
  font-family: var(--tajwal);
  color: var(--sub-text-color);
}
ion-icon {
  font-size: 40px;
}
.sub-text {
  font-family: var(--tajwal);
  color: var(--sub-text-color);
}
.link {
  text-decoration: none;
  color: var(--link);
  font-size: 16px;
  margin-top: 10px;
  display: block;
  font-family: "Cairo";
}
.link-subtitle {
  font-size: 14px;
}
.subtitle {
  margin: 0 0 0 0;
  font-family: var(--cairo);
}
.dark {
  color: var(--sub-text-color);
}
.orange {
  color: #ff9e33;
}
h1 {
  font-family: var(--cairo);
  font-weight: 400;
  margin: 20px 0 0 0;
  font-size: 20px;
}
h2 {
  color: var(--sub-text-color);
  font-family: var(--cairo);
  font-size: 18px;
}
.text {
  font-family: var(--tajwal);
  font-weight: 400;
  width: 80%;
  margin: 0 auto 0 auto;
}

img {
  width: 50%;
  margin-top: 20px;
}
.gpio {
  width: 90%;
  margin: 0 auto 20px auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.code {
  width: 80%;
  margin: 0 auto 0 auto;
  border: 1px solid var(--sub-text-color);
  padding: 5px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.thank-you {
  font-family: "Cairo";
  font-size: 20px;
  text-align: center;
  color: white;
}
.info-text {
  display: inline-block;
  margin: 5px;
  font-family: var(--tajwal);
  color: white;
  font-size: 12px;
}
.logo-container {
  background: #f4f4f4;
  margin-top:50px;
  margin-bottom:20px;
}
.logo {
  margin: 0 0 0 0;
  width: 40px;
}
.divider {
  border: none;
  border-top: 1px solid var(--light-black);
  width: 100%;
}
.card-invisible {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 90%;
  margin: 0 auto 20px auto;
}
</style>

<template>
  <div class="main-container">
    <h1>قسم الدراسات العملية</h1>
    <p class="element-text dark">أحمد عبدالعزيز القطان</p>
    <img alt="Vue logo" src="../assets/img.png" />
    <p class="text">ورشة عمل: الأردوينو والريلاي</p>
    <div class="container">
      <hr />
    
      <hr class="divider" />
      <div class="element">
        <div class="flex-element-right">
          <ion-icon name="notifications-outline"></ion-icon>
        </div>
        <div class="flex-element-left">
          <p class="element-text">يتشرف قسم الدراسات العملية بمدرسة أحمد عبدالعزيز القطان المتوسطة، بدعوتكم لحضور ورشة بعنوان الأردوينو و الريلاي</p>
          
          
        </div>
      </div>
     
 <hr class="divider" />
      <div class="element">
        <div class="flex-element-right">
          <ion-icon name="hammer-outline"></ion-icon>
        </div>
        <div class="flex-element-left">
          <p class="element-text">تستعرض المحاضرة أنواع الريلاي وطرق استخدامه بالربط مع الأردوينو</p>
          
          
        </div>
      </div>
      <hr class="divider" />
      <div class="element">
        <div class="flex-element-right">
          <ion-icon name="calendar-outline"></ion-icon>
        </div>
        <div class="flex-element-left">
          <p class="element-text dark">6/12/2023</p>
          <p class="element-text">الأربعاء</p>
        </div>
      </div>
      <hr class="divider" />
      <div class="element">
        <div class="flex-element-right">
          <ion-icon name="time-outline"></ion-icon>
        </div>
        <div class="flex-element-left">
          <p class="element-text dark">من 8:30 صباحا</p>
          <p class="element-text">إلى 11:30 صباحا</p>
        </div>
      </div>
      <hr class="divider" />
      <div class="element">
        <div class="flex-element-right">
          <ion-icon name="location-outline"></ion-icon>
        </div>
        <div class="flex-element-left">
          <p class="element-text dark">مدرسة أحمد عبدالعزيز القطان</p>
          <p class="element-text dark">قاعة المسرح</p>
          <p class="element-text">قطعة 6، شارع 617</p>
          <a href="https://maps.app.goo.gl/EskwLEPuZsUecJhd6" target="_blank" class="element-text link orange">الذهاب إلى Google Maps</a>
          
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2070.1559128582435!2d47.9005935087749!3d29.236100921404823!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fcf97137a2e90f7%3A0xad74bea7463943c8!2z2YXYr9ix2LPYqSDYo9it2YXYryDYudio2K8g2KfZhNi52LLZitiyINin2YTZgti32KfZhiDZhSDYqNmG2YrZhg!5e0!3m2!1sen!2skw!4v1697523975574!5m2!1sen!2skw" width="90%" style="border: 0; margin-top: 10px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
     
      <hr class="divider" />
      
      <hr />
    </div>
    <div>
      <p class="info-text">مدرسة أحمد عبدالعزيز القطان</p>
      <p class="info-text">قسم الدراسات العملية</p>
      <p class="info-text">رئيس القسم: الأستاذ عدنان الضاحي</p>

      <p class="info-text">إشراف الموجه الفني: الأستاذ أحمد الوهيدة</p>
      
      <p class="info-text">مدير المدرسة: الأستاذ مصطفى الرشيدي</p>
    </div>

    <div class="logo-container">
      <img class="logo" alt="Vue logo" src="../assets/logo.png" />
    </div>
  </div>
</template>

<script>

export default {
  name: "HelloWorld",
  props: {
    msg: String,
   
  },
  
data(){
  return{
     isTime: false,
  }
},
  mounted() {
    // setInterval(() => {
    //   var d = new Date()
     
    //   if(d.getDate() == 22 && (d.getMonth() + 1) == 11 && d.getHours() >= 8 && d.getMinutes() >= 30 ){
    //     this.isTime = true
    //   }
    //   else this.isTime = false;
      
    // }, 1000)
  },
  methods:{
    register(){
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
hr {
  border: none;
  border-top: 3px solid #ff9e33;
  width: 60%;
}
.element {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  margin: 0 auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-element-right {
  flex: 1;
}
.flex-element-left {
  flex: 3;
  text-align: right;
  font-family: var(--tajwal);
}
.element-text {
  font-size: 16px;
  margin: 0 0 0 0;
  font-family: var(--tajwal);
}
.image-title {
  font-size: 16px;
  margin: 0 0 0 0;
  font-family: var(--tajwal);
  color: var(--sub-text-color);
}
ion-icon {
  font-size: 40px;
}
.sub-text {
  font-family: var(--tajwal);
  color: var(--sub-text-color);
}
.link {
  text-decoration: none;
  color: var(--link);
  font-size: 16px;
  margin-top: 10px;
  display: block;
}
.link-subtitle {
  font-size: 14px;
}
.subtitle {
  margin: 0 0 0 0;
  font-family: var(--cairo);
}
.dark {
  color: var(--sub-text-color);
}
.orange {
  color: #ff9e33;
}
h1 {
  font-family: var(--cairo);
  font-weight: 400;
  margin: 20px 0 0 0;
  font-size: 20px;
}
h2 {
  color: var(--sub-text-color);
  font-family: var(--cairo);
  font-size: 18px;
}
.text {
  font-family: var(--tajwal);
  font-weight: 400;
  width: 80%;
  margin: 0 auto 0 auto;
}

img {
  width: 50%;
  margin-top: 20px;
}
.gpio {
  width: 90%;
  margin: 0 auto 20px auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.code {
  width: 80%;
  margin: 0 auto 0 auto;
  border: 1px solid var(--sub-text-color);
  padding: 5px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.info-text {
  display: inline-block;
  margin: 5px;
  font-family: var(--tajwal);
  color: white;
  font-size: 12px;
}
.logo-container {
  background: #f4f4f4;
}
.logo {
  margin: 0 0 0 0;
  width: 40px;
}
.divider {
  border: none;
  border-top: 1px solid var(--light-black);
  width: 100%;
}
.card-invisible {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 90%;
  margin: 0 auto 20px auto;
}

/* CSS */
.btn,
.btn2 {
  width: 300px;
  height: 100px;
  font-size: 30px;
  text-align: center;
  line-height: 100px;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 50px;
  background: linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #3cf0c5);
  background-size: 600%;
  animation: anime 16s linear infinite;
}
.btn2 {
  position: absolute;
  margin-top: -70px;
  z-index: -1;
  filter: blur(30px);
  opacity: 0.8;
}
@-moz-keyframes anime {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-webkit-keyframes anime {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-o-keyframes anime {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes anime {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* CSS */
.btn-animated {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  border: none;
  font-family: "Cairo";
  padding: 10px;
  font-size: 20px;
  width: 80%;
  display: block;
  margin:0 auto 0 auto;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}
.btn-animated:active {
  background: white;
  color: black;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>

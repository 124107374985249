import { createApp } from 'vue'
import App from './App.vue'
import HelloWorld from "./components/HelloWorld.vue";
import RegisterView from "./components/RegisterView.vue";
import TeachersView from "./components/TeachersView.vue";

import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    { path: '/', component: HelloWorld },
    { path: '/register', component: RegisterView },
    { path: '/teachers', component: TeachersView },
  ]
  const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
  })
  const app = createApp(App);
  app.use(router)
 
  app.mount('#app');
